<template>
    <div>
        <ULink to="/" class="font-prosto" >
            Plunni Hire
        </ULink>
    </div>
</template>

<script setup>
const colorMode = useColorMode()
</script>